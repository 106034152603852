import Login from "./views/Login/Index";
import "./app.css";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    document.title = window.config.title;
  }, []);
  return (
    <div className="app_container">
      <Login />
    </div>
  );
}

export default App;
