import { Button, Col, Form, Input, Layout, Row, message } from "antd";
import "./index.css";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useState } from "react";

const { Header, Footer, Content } = Layout;

const Index = () => {
  const [form] = Form.useForm<{
    username: string;
    password: string;
  }>();

  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    window.open("https://beian.miit.gov.cn/", "_blank");
  };

  const handleLogin = async () => {
    try {
      await form.validateFields();
    } catch (e: any) {
      console.error("[ e ] >", e);
      return;
    }

    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      message.error("用户名或密码错误");
    }, 1500);
  };
  return (
    <div className="login_container">
      <Layout className="layout">
        <Header className="header">{window.config.title}</Header>
        <Content className="content">
          <div className="form">
            <div className="title">用户登录</div>
            <Form form={form}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="用户名"
                    name="username"
                    rules={[{ required: true, message: "请输入用户名" }]}
                  >
                    <Input
                      placeholder="用户名"
                      prefix={
                        <UserOutlined style={{ color: "rgba(0,0,0,.3)" }} />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="密　码"
                    name="password"
                    rules={[{ required: true, message: "请输入密码" }]}
                  >
                    <Input
                      placeholder="密码"
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.3)" }} />
                      }
                      type="password"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      loading={loading}
                      style={{ width: "100%" }}
                      type="primary"
                      onClick={handleLogin}
                    >
                      登录
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
        <Footer className="footer">
          <div onClick={handleClick} className="batitle">
            {window.config.copyright}
          </div>
        </Footer>
      </Layout>
    </div>
  );
};

export default Index;
